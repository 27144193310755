/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */
@import "variables/variables";

.brand-logo img {
  width: 36px;
  height: 32px;
}

.navigation {
  font-size: 1rem;
}

.virtual-terminal:not(.virtual-terminal-main .virtual-terminal) {
  margin: 2rem;

  .card {
    width: 100%;
    // max-width: 500px;
    // margin-left: 50%;
    // transform: translate(-50%, 0);
  }
}

.payment-home {
  margin: 2rem;

  .card {
    width: 100%;
    max-width: 500px;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
}

.cardMaster:hover {
  background-color: #eee;
  cursor: pointer;
}

@media print {
  .hide-print {
    display: none !important;
  }

  body {
    background-color: #ffffff !important;
  }

  @page {
    size: A4;
  }
}

.rdt_TableCell {
  height: auto !important;
}

.virtual-terminal {
  .card {
    width: 100%;
    max-width: 500px;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }
}

#statement-icons-container .card {
  width: 115px;
}

#statement-icons-container .card:hover {
  background-color: $primary;
  color: #ffffff;
  border-color: $primary;
}

.total-amount {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* Optionally, you can use ellipsis to indicate hidden content */
}

////////////////////////////////////////////////////////////////////////////////////////////////////////

.payment-home-pg {
  background-color: #393b48;
  flex-direction: column;
  height: 1245px;
  width: 100%;
  padding: 0px;
  box-sizing: border-box;

  .card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 80% !important;
    max-width: 450px;
    margin: 0 auto;
    height: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background: #000000;
    padding: 0px;
    box-sizing: border-box;
    margin-top: 0px;
    margin-left: 50%;
    transform: translate(-50%, 0);
  }

  .form-field {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px !important;
  }

  .form-control {
    background-color: #25262C !important;
    color: #ffffff !important;
  }

  .select__control .select__indicator svg,
  .react-select__control .select__indicator svg {
    color: #777474 !important;
  }

  .form-control-sm {
    min-height: 2.7rem !important;
    font-size: 1.1rem !important;
  }

  .form-control-sm::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #ffffff;
    opacity: 1;
    /* Firefox */
  }

  .form-control-sm:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #ffffff;
  }

  .form-control-sm::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #ffffff;
  }

  .form-control-sm::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #ffffff;
  }

  /* Added styles for handling autofill */
  input.form-control-sm:-webkit-autofill,
  input.form-control-sm:-webkit-autofill:hover,
  input.form-control-sm:-webkit-autofill:focus,
  textarea.form-control-sm:-webkit-autofill,
  textarea.form-control-sm:-webkit-autofill:hover,
  textarea.form-control-sm:-webkit-autofill:focus,
  select.form-control-sm:-webkit-autofill,
  select.form-control-sm:-webkit-autofill:hover,
  select.form-control-sm:-webkit-autofill:focus {
    -webkit-text-fill-color: #ffffff !important;
    -webkit-box-shadow: 0 0 0px 1000px #25262C inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }

  .form-control:focus {
    outline: #ffffff !important;
    border-color: #ffffff !important;
    /* Keeps the border color unchanged */
    box-shadow: #ffffff !important;
    /* Removes any focus shadow, if there is one */
  }

  /* Targeting autofill in Firefox */
  input.form-control-sm:-moz-autofill,
  input.form-control-sm:-moz-autofill:hover,
  input.form-control-sm:-moz-autofill:focus,
  textarea.form-control-sm:-moz-autofill,
  textarea.form-control-sm:-moz-autofill:hover,
  textarea.form-control-sm:-moz-autofill:focus,
  select.form-control-sm:-moz-autofill,
  select.form-control-sm:-moz-autofill:hover,
  select.form-control-sm:-moz-autofill:focus {
    box-shadow: 0 0 0px 1000px #393b48 inset !important;
  }

  // .input-field {
  //   width: 100%!important;
  //   padding: 0.75rem 1rem!important; /* Adjust the padding as needed */
  //   border: 1px solid #ccc!important; /* Add a border as needed */
  //   border-radius: 0.5rem!important; /* Adjust the border-radius as needed */
  // }

  .react-select__control {
    padding: 0.375rem 0 !important;
    border-radius: 0.5rem !important;
    /* Adjust the border-radius as needed */
  }

  /* When using react-select, you might need to target inner elements */
  .react-select__value-container,
  .react-select__single-value {
    padding: 0.375rem 0 !important;
  }

  /* Adjust invalid input styles */
  .is-invalid .react-select__control {
    border-color: #dc3545 !important;
    /* Red border for invalid input */
  }

  .is-invalid .input-field {
    border-color: #dc3545 !important;
    /* Red border for invalid input */
  }

  .no-gutter > [class^="col-"] {
    padding-right: 5px !important;
    /* Adjust the right padding as needed */
    padding-left: 5px !important;
    /* Adjust the left padding as needed */
  }

  .row {
    --bs-gutter-x: 0rem !important;
  }

  .card-body,
  .card-header {
    background-color: #000000 !important;
  }

  .card-body {
    height: 100vh !important;
  }

  .card-header {
    padding: 0px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .added-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 0px;
    /* This is the space between the cards */
  }

  .btn-outline-primary {
    border: 1px solid #0399aa !important;
    background-color: #49a8ff;
    color: white;
  }

  .btn-outline-danger {
    border: 1px solid #ea5455 !important;
    background-color: #ff0000;
    color: white;
  }

  .cardMaster {
    position: relative;
    background-color: #4e505d;
    border: none !important;
  }

  .cardMaster:hover {
    background-color: #4e505d;
    opacity: 0.8;
  }

  .card-column {
    width: calc(50% - 10px);
    /* Two cards per row with 10px space in between */
    margin-bottom: 20px;
    /* Space between rows of cards */
  }

  .payment-summary {
    max-width: 100%;
    margin: auto;
    background-color: #25262C;
    color: rgb(245, 243, 243);
    border-radius: 10px !important;
    padding: 10px !important;
    text-align: center;
    // border: 0.5px solid #d8d2d2;
    box-shadow: 0px 0px #00000080;
  }

  .payment-summary h4 {
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 20px;
    color: #d9d9d9;
  }

  .payment-summary h2 {
    margin-bottom: 10px;
    font-size: 36px;
    font-weight: bold;
    color: #ffffff;
  }

  .details-button a {
    text-decoration: none;
    color: #49a8ff;
    padding: 10px 20px;
    font-size: 15px;
    display: inline-block;
  }

  .details-button a:hover {
    text-decoration: none;
    opacity: 0.8;
  }

  /* App.css */

  .payment-container {
    font-family: "Arial", sans-serif;
    color: white;
    background-color: #121212;
    /* Dark background */
    width: 100%;
    max-width: 360px;
    margin: auto;
    padding: 20px;
    border-radius: 10px;
  }

  .dark-mode {
    background-color: #121212;
    /* Dark background for dark mode */
  }

  .payment-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    color: white;
    background-color: #4caf50;
    /* Example button color, adjust as necessary */
    border: none;
    border-radius: 5px;
    font-size: 1em;
  }

  .footer-links {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
  }

  .footer-links a {
    color: #49a8ff;
    /* Example link color */
    text-decoration: none;
  }

  .reference {
    text-align: left;
    font-size: 16px;
    margin-top: 10px;
    margin-left: 6px;
    color: #ffffff;
  }

  .payment-summary-body {
    padding-bottom: 10px;
    margin-top: 20px;
    // margin-left: 20px;
    margin-bottom: 10px;
  }

  .payment-summary-footer {
    border-top: 1px solid #555;
    padding-top: 10px;
  }

  .payment-detail {
    display: flex;
    justify-content: start;
    /* Align items to the start of the container */
    align-items: center;
    margin-bottom: 8px;
    /* Adjust the margin as per your design */
  }

  .payment-label {
    font-size: 15px;
    color: #ffffff;
    text-align: left;
  }

  .payment-value {
    font-size: 15px;
    color: #ffffff;
    white-space: nowrap;
    margin-left: auto;
    // width: 23%;
    display: flex;
  }

  .payment-details-label {
    text-align: center;
    margin-left: 35px;
    font-size: 13px;
    color: #ffffff;
  }

  .payment-details-value {
    margin-left: auto;
    margin-right: 0px;
    font-size: 13px;
    color: #ffffff;
    white-space: nowrap;
    width: 23%;
    display: flex;
  }

  .payment-options-text {
    color: #ffffff;
    /* White text color */
    margin-bottom: 15px;
    /* Space between text and buttons */
    margin-top: 15px;
    /* Space between text and buttons */
    font-size: 16px;
    /* Text size */
    text-align: center;
    /* Center text */
  }

  .payment-methods-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: flex-start;
  }

  .payment-method-button {
    flex: 1 0 calc(33.333% - 20px);
    /* Subtract the gap from the width */
    max-width: calc(33.333% - 20px);
    /* To ensure that the button does not exceed the calc width */
    box-sizing: border-box;
    /* To ensure padding and border are included in the width */
    padding: 10px;
    text-align: center;
    background-color: transparent;
    color: #000000;
    border: 2px solid #fdfcfc; /* Add a border */
    border-radius: 10px; /* Optional: Add border radius for rounded corners */
  }

  .pay-now-button {
    background-color: #34c759 !important;
    /* Adjust Pay Now button color as necessary */
    color: white;
    padding: 10px 30px;
    border: none;
    border-radius: 10px;
    width: 100%;
    font-size: 1.2em;
    margin-top: 20px;
  }

  .additional-options {
    display: flex;
    justify-content: space-around;
    /* This will distribute space evenly between the buttons */
  }

  .option-btn {
    padding: 5px;
    border: 1px solid #ccc;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    cursor: pointer;
    font-size: 10px;
    width: 100%;
    text-align: center;
    color: white;
  }

  .option-btn:last-child {
    margin-right: 0;
    /* No margin for the last button */
  }

  .icon-container {
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: flex-start;
    /* Align horizontally to the left */
  }

  .icon {
    margin-right: 8px;
    align-self: flex-start;
  }

  .total-amount-label {
    margin-top: 10px !important;
    text-transform: uppercase !important;
    color: #ffffff !important;
  }

  .total-amount-value {
    font-weight: bold !important;
    font-size: 32px !important;
    margin-top: 0 !important;
    color: #34c759 !important;
  }

  .payment-method-button:hover {
    background-color: transparent;
    color: #ffffff;
    opacity: 0.8;
  }

  .pay-now-button:hover {
    background-color: #34c759;
    opacity: 0.8;
  }

  .option-btn:hover {
    background-color: rgba(0, 0, 0, 0.2);
    opacity: 0.8;
  }

  .payment-method-button.active {
    background-color: #4caf50;
    /* Example active color */
    color: white;
    /* Other styles to indicate active state, such as a border or shadow */
  }

  .back-button-container {
    margin-right: auto;
    /* Pushes everything else to the right */
  }

  .back-icon {
    display: inline-block;
    margin-right: 1px;
    height: 11px;
    width: 6px;
  }

  /* For the hover effect */
  .back-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    opacity: 0.8;
  }

  .back-button {
    background: #393b48;
    border: none;
    color: white;
    font-size: 11px;
    font-weight: lighter;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    cursor: pointer;
    position: absolute;
    padding: 1px 7px;
    margin-top: 39px;
    left: 0;
    top: 0;
  }

  .payment-logo {
    width: 80%;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .card-name {
    font-size: 1rem;
    /* Adjust the font size as needed */
    font-weight: lighter;
    /* Make the name bold */
    margin-bottom: 5px;
    color: #ffffff;
    font-size: 12px;
  }

  .card-number {
    margin-bottom: 0px;
    margin-top: 10px;
    font-size: 14px;
    color: #ffffff;
  }

  .card-expiry {
    font-size: 10px;
    color: white;
    margin-top: 25px;
    margin-right: 0px;
  }

  .badge {
    position: absolute;
    top: 10px;
    /* Adjust the top position as needed */
    right: 10px;
    /* Adjust the right position as needed */
    padding: 2px 8px;
    /* Adjust the padding as needed */
    font-size: 0.7rem;
    /* Adjust the font size as needed */
    border-radius: 4px;
    /* Rounded corners for the badge */
  }

  .badge-expired {
    background-color: #ff6b6b;
    /* Example expired badge color */
    color: white;
  }

  .checkbox-container {
    margin-left: 0px;
  }

  .new-pg {
    input::placeholder {
      color: #000000;
      /* Placeholder text color */
    }

    /* For Internet Explorer */
    input:-ms-input-placeholder {
      color: #000000;
      /* Placeholder text color */
    }

    .form-check-input:checked {
      background-color: #38be51 !important;
      border-color: #38be51 !important;
    }

    .form-check-input:focus:checked {
      box-shadow: 0 0 0 0.25rem rgba(56, 190, 81, 0.25) !important;
    }

    .form-check-input:checked::before {
      color: #fff !important;
    }
  }

  .CVVCardImage {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-image: url("../images/icons/cvv%201.svg");
    background-size: 30px 40px;
    /* Smaller fixed size */
    background-position: right 10px center;
    /* Adjust right padding as needed */
    background-repeat: no-repeat;
  }

  .CreditCardContainer {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    background-image: url("../images/icons/visa-mastercard2%201.svg");
    background-size: 80px 100px;
    /* Smaller fixed size */
    background-position: right 10px center;
    /* Adjust right padding as needed */
    background-repeat: no-repeat;
  }

  .py-2 {
    padding-bottom: 1rem !important;
  }

  .px-3 {
    padding-right: 3rem !important;
    padding-left: 1rem !important;
}

.next-btn {
  border-radius: 25px;
}

// .custom-shipping-country-style {
//   border-left: 1px solid #444;
//   border-right: 1px solid #444;
//   border-bottom: 1px solid #444;
//   border-radius: 0px !important;
// }
.css-1ozehwu-control {
  border-radius: 0px !important;
}

.address-button {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
  background-color: #25262b;
  color: white;
  border-radius: 5px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  border: 1px solid #6f7280;
}

.address-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid #ccc;
  border-radius: 20%;
  margin-right: 10px;
  color: #ccc;
}

}

.add-tip {
  margin-left: 5px;
  color: white;
}

.add-tip-button {
  color: white;
  width: 70px;
  margin-left: 0px;
  font-size: 10px;
  background-color: #111112 !important;
}

@media (max-width: 576px) {
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }

  .option-btn {
    font-size: 8px !important;
  }

  .add-tip {
    margin-left: 8px !important;
    color: white;
  }

  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .payment-details-value {
    margin-left: auto !important;
    margin-right: 23px !important;
  }

  .payment-details-label {
    margin-left: 60px !important;
  }

  .receiptContainer {
    width: 100% !important;
    padding: 15px !important;
    /* Add some padding */
    background-image: url("../images/icons/paper-card.png") !important;
    background-repeat: no-repeat !important;
    flex-direction: column !important;
    margin-left: 1px !important;
    margin-top: 6px !important;
  }

  .transaction-header {
    margin-left: 0px !important;
    font-size: 9px !important;
    font-weight: 600 !important;
    line-height: 0px !important;
  }

  .signature {
    margin-left: 0px !important;
    font-size: 4px !important;
  }

  .cunstomer-hr {
    margin-left: 0px !important;
    flex: auto !important;
  }

  .receipt {
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
    margin-left: 3px !important;
  }

  .customer-hr {
    margin-left: 3px !important;
    flex: auto !important;
  }

  .customer-label {
    font-size: 7px !important;
    font-weight: 700 !important;
    line-height: 0px !important;
    text-align: left !important;
  }

  .aux-logo {
    margin-top: 0px !important;
    margin-right: 3px !important;
  }

  .customer-header {
    margin-left: 3px !important;
    font-size: 9px;
    font-weight: 600;
    line-height: 0px;
  }

  .receipt-header {
    font-size: 15px !important;
    margin-left: 0px !important;
    margin-top: 12px !important;
    color: black;
  }

  .receipt-align {
    margin-left: 3px !important;
    font-size: 7px !important;
    font-weight: 500 !important;
    line-height: 0px;
  }

  .receipt-card {
    margin-left: 12px !important;
    width: 95% !important;
  }
}

.first-modal-text {
  padding-bottom: 10px;
  margin-top: 30px;
  color: white;
}

.second-modal-text {
  padding-bottom: 10px;
  margin-top: 30px;
  color: white;
}

.modal-custom-class {
  .modal-body {
    background-color: #25262C !important;
    height: 75%;
    border-radius: 10px 10px 10px 10px;
    width: 75%;
    left: 10%;
  }

  .modal-content {
    background-color: transparent !important;
  }

  .modal-footer-custom {
    display: flex;
    justify-content: center;
  }
}

.trans-failure-card {
  background-color: #393b48;
  /* dark background color as per modal */
  border-radius: 10px 30px 10px 10px;
  /* rounded corners as in modal */
  border: none;
  /* no border as modal seems to have none */
  position: relative;
  width: 100%;
  /* width as per modal style */
  height: 290px;
  /* height as per modal style */
  left: 0%;
  /* centering as per modal style */
  margin: 0px;
  /* center the signature pad horizontally */
  margin-top: 70px;

  .trans-failure-button {
    width: 85% !important;
    margin-left: 35px;
    margin-bottom: 10px !important;
    background-color: #ff0000 !important;
  }
}

.payment-confirmation-modal .modal-header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payment-confirmation-modal .btn {
  padding: 10px 20px;
  font-size: 1rem;
}

.payment-confirmation-modal .modal-body-custom {
  background-color: #636363;
  /* Dark background */
  border-radius: 10px 30px 10px 10px;
  position: relative;
  padding: 20px;
  color: white;
}

.btn-close-circle {
  position: absolute;
  top: 0;
  /* Adjust as needed, considering the modal padding */
  right: 7px;
  /* Adjust as needed, considering the modal padding */
  background-color: rgba(255, 255, 255, 0.1);
  border: 2px solid rgba(255, 255, 255, 0.1);
  /* Circle border */
  border-radius: 50%;
  padding: 0;
  width: 30px;
  height: 30px;
  cursor: pointer;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  z-index: 1050;
  /* Above the modal content */
}

.close-container {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding-bottom: 1px;
    padding-left: 1px;
    height: 25px;
    width: 20px;
  }
}

.close-container:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.1);
  opacity: 0.8;
}

.close-container::before {
  color: white;
  font-weight: bold;
  height: 11px !important;
  width: 6px !important;
}

////////////////////////////////////

.close-container {
  position: absolute;
  top: 10px;
  right: 10px;
  border: 1px solid #ccc;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.checkmark-container {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.checkmark-icon {
  width: 40px;
  /* Adjust size as necessary */
  height: 40px;
  /* Adjust size as necessary */
}

.checkmark-container {
  position: absolute;
  /* absolute position relative to its positioned parent */
  top: 15%;
  /* 50% from the top of the parent */
  left: 50%;
  /* 50% from the left of the parent */
  transform: translate(-50%, -50%);
  /* shift it back by its own 50% to truly center it */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #4caf50;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkmark-container img {
  /* make sure the image fits inside the container without stretching it */
  max-width: 100%;
  max-height: 100%;
}

.checkmark-container::before {
  color: white;
  font-weight: bold;
}

.payment-text {
  color: #ffffff;
}

.payment-confirmation-content {
  .card-name {
    font-size: 12px;
    margin-bottom: 0;
    margin-left: 25px;
    margin-top: 7px;
    color: white;
  }

  .card-number {
    margin-top: 0;
    font-size: 12px;
    color: white;
    margin-left: 25px;
  }

  .card-expiry {
    font-size: 12px;
    color: white;
    margin-right: 25px;
  }

  .payment-card-custom {
    color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 70px;
  }
}

.payment-confirmation-text {
  text-align: center;
  margin-bottom: 10px;
  margin-top: 60px;
  color: #ffff;
}

.pay-button {
  background-color: #28a745;
  /* Green, adjust as needed */
}

.cancel-button {
  background-color: #dc3545;
  /* Red, adjust as needed */
}

.payment-details h5 {
  margin-bottom: 0.25rem;
  color: #ffffff;
}

.payment-details .card-number {
  margin-bottom: 0.5rem;
}

.payment-details .expiry-date {
  margin-bottom: 1rem;
}

.payment-card {
  background: rgba(0, 0, 0, 0.1);
  /* Card background */
  padding: 15px;
  border-radius: 10px;
  text-align: center;
  margin-bottom: 15px;
  margin-top: 30px;
}

.payment-confirm-button {
  width: 100%;
  margin-bottom: 0px;
  background-color: #38be51 !important;
}

.payment-confirm-button:active {
  width: 100%;
  margin-bottom: 0px;
  background-color: #38be51 !important;

  .btn-primary {
    background-color: #38be51 !important;
  }
}

.payment-cancel-button {
  width: 100%;
  margin-bottom: 10px;
  background-color: #ff0000 !important;
}

.payment-card-name,
.payment-card-number,
.payment-card-expiry {
  margin-bottom: 5px;
}

.payment-card-custom {
  background: rgba(0, 0, 0, 0.1);
  /* Adjust color as needed */
  border-radius: 8px;
  padding: 0px;
  color: white;
  margin-bottom: 12px;
  margin-top: 30px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.card-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  width: 100%;
}

.expired-badge {
  position: absolute;
  top: 0;
  right: 0;
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 0 0 0 5px;
  /* rounded bottom-left corner */
}

.receiptContainer {
  width: 436px;
  height: 600px;
  background-image: url("../images/icons/paper.png");
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -15px;
}

.receiptTitle {
  margin-top: 27px;
  margin-left: 16px;
  font-size: 16px;
  color: white;
}

.receipt-align {
  margin-left: 44px;
  font-size: 7px;
  font-weight: 500;
  line-height: 0px;
}

.receipt-card {
  margin-left: 35px;
  width: 92%;
}

.custom-footer {
  width: 64.666667% !important;
  margin-top: 46px !important;
}

.custom-page {
  margin-top: 46px !important;
}

.transaction-data {
  font-size: 8px;
  font-weight: 700;
  line-height: 0px;
}

.transaction-data-description {
  line-height: 8px !important;
  font-size: 7px;
}

.transaction-labels {
  font-size: 8px;
  font-weight: 500;
  line-height: 0px;
}

.customer-details {
  font-size: 8px;
  font-weight: 500;
  line-height: 0px;
  margin-left: 18px;
}

.customer-label {
  font-size: 7px;
  font-weight: 700;
  line-height: 0px;
}

.amount {
  line-height: 0px;
  font-size: 12px;
  font-weight: 600;
}

.approved {
  font-size: 10px;
  font-weight: 500;
  color: #38be51;
  line-height: 15px;
}

.receipt-header {
  font-size: 17px;
  margin-left: 44px;
  margin-top: 45px;
  color: black;
}

.transaction-label {
  font-size: 9px;
  font-weight: 600;
  line-height: 0px;
}

.footer {
  font-size: 12px;
  font-weight: 600;
  line-height: 0px;
}

.footer-pg {
  font-size: 5px;
  font-weight: 600;
  line-height: 0px;
}

.footer-page {
  font-size: 5px;
  font-weight: 600;
  line-height: 0px;
  margin-left: 43px;
}

.customer-header {
  margin-left: 44px;
  font-size: 9px;
  font-weight: 600;
  line-height: 0px;
}

.cunstomer-hr {
  width: 76%;
  margin-left: 39px;
}

.customer-hr {
  width: 73%;
  margin-left: 45px;
}

.transaction-header {
  font-size: 9px;
  font-weight: 600;
  line-height: 0px;
  margin-left: 38px;
}

.hr-alignment {
  width: 70% !important;
  margin-left: 32px !important;
}

.card-payment {
  font-size: 9px;
}

.receipt {
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
  margin-left: 10px;
}

.transaction-head {
  font-size: 8px;
  font-weight: 600;
  line-height: 0px;
  margin-top: 10px !important;
}

.customer-main {
  margin-left: 5px !important;
}

.signature {
  font-size: 4px;
  margin-top: 21px !important;
  margin-left: 13px;
}

.aux-logo {
  margin-top: 36px;
  margin-right: 48px;
}

.set-width {
  width: 60% !important;
}

.transaction-col {
  width: 37.333333% !important;
}

.set-amount-width {
  width: 20.666667% !important;
}

/* Customize further based on your needs */

.unique-radio-container .custom-radio .form-check-input:checked {
  background-color: #38be51;
  /* Example: Blue color when checked */
  border-color: #38be51;
  /* Matching border color */
}

.unique-radio-container .custom-radio .form-check-label {
  /* Custom styles for the label */
  color: white;
  /* Example: Dark label color */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22px;

  /* adjust as needed */
  .pay-now-button {
    background-color: #323441 !important;
    padding: 4px 30px;
    border: 1px solid #ffffff;
    border-radius: 10px;
    margin-top: 10px;
    height: 35px;
    width: 80%;
  }

  img {
    margin-right: 0.5em;
  }
}

.print-button {
  background-color: transparent !important;
  border: none;
  padding: 0;
  color: white;
  font-size: 12px;
  margin-left: 60px;
  margin-top: 28px;

  img {
    margin-right: 0.5em;
  }
}

.modal-content-flex {
  display: flex;
  position: relative;
  /* Needed for absolute positioning of children */
}

/* Modal Card Container */
.modal-card-container {
  width: 100%; /* Full width */
  padding: 0px; /* Padding around the card */
  margin-top: 25px;
}

/* Modal Payment Card */
.modal-payment-card {
  background-color: #2e2f38; /* Dark card background */
  border-radius: 8px; /* Rounded corners */
  padding: 12px 16px; /* Inner padding for spacing */
  display: flex; /* Flex layout for horizontal alignment */
  justify-content: space-between; /* Space between left and right sections */
  align-items: center; /* Vertically align content */
  width: 100%; /* Full width */
}

/* Card Logo */
.modal-card-logo {
  width: 50px; /* Logo size */
  height: auto; /* Maintain aspect ratio */
  margin-right: 15px; /* Spacing between logo and text */
}

/* Card Information (Name and Number) */
.modal-card-info {
  display: flex;
  flex-direction: column; /* Stack name and number vertically */
}

.modal-card-name {
  font-size: 16px; /* Larger font for card name */
  color: #ffffff; /* White text */
  font-weight: bold; /* Bold font */
  margin-bottom: 5px; /* Spacing below the name */
}

.modal-card-number {
  font-size: 14px; /* Slightly smaller font */
  color: #ffffff; /* Gray color for the card number */
}

/* Expiry Date */
.modal-card-expiry {
  font-size: 11px; /* Same font size as the card number */
  color: #ffffff; /* Gray color for expiry text */
  margin-left: auto; /* Push expiry to the far right */
  margin-top: 27px;
}

.tip-sidebar {
  margin-top: 252px;
  height: 31%;
  display: flex;
  flex-direction: column;
  width: 100%;
  position: absolute;
  top: 0%;
  z-index: 10;
  color: black;
  background-color: #393b48;
  border-radius: 0px 0px 10px 10px !important;
}

.tip-row {
  width: 80%;
}

.add-tip-container {
  display: flex;
  flex-direction: column;
  background-color: #000;
  /* Sidebar background color */
  padding: 20px;
  color: white;
}

.add-tip-label {
  font-size: 13px;
  margin-bottom: 1rem;
  /* Spacing between the label and options */
}

.tip-options {
  width: 100%;
  /* Make sure it takes up the full width of its container */
}

.tip-pay-button {
  width: 88%;
  // margin-top: 10px;
  background-color: #38be51 !important;
  color: white;
  font-size: 12px !important;
}

.custom-tip-input-container {
  width: 100%;
  /* Custom tip input should match button width */
  padding: 0;
  margin-left: 4px !important;
  width: 89%;
  margin-top: 7px !important;
}

.tip-cancel-button {
  width: 88%;
  background-color: #ff0000 !important;
  margin-left: 23px !important;
  // color: white;
  font-size: 12px !important;
}

.tip-button.active {
  background-color: #ffffff;
  color: black;
}

.add-tip-header {
  font-size: 16px;
  /* Adjust the size as needed */
  color: white;
  /* Set the color to white */
  align-self: flex-start;
  /* Aligns the header to the left */
  margin-bottom: 7px;
  margin-top: 12px;
  margin-left: 4px;
  text-align: center;
}

.tip-button {
  margin-left: 0px !important;
}

.add-tip-value-header {
  font-size: 12px;
  /* Adjust the size as needed */
  color: white;
  /* Set the color to white */
  align-self: flex-start;
  /* Aligns the header to the left */
  margin-bottom: 10px;
  margin-top: 12px;
  margin-left: 24px !important;
}

/////////////////////////////////////////////////////////////

@media (max-width: 576px) {
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .add-tip {
    margin-left: 8px !important;
    color: white;
  }

  .payment-details-value {
    margin-left: auto !important;
    margin-right: 23px !important;
  }

  .payment-details-label {
    margin-left: 60px !important;
  }

  .receiptContainer {
    width: 95% !important;
    padding: 15px !important;
    /* Add some padding */
    background-image: url("../images/icons/paper-card.png") !important;
    background-repeat: no-repeat !important;
    flex-direction: column !important;
    margin-left: 6px !important;
    margin-top: 6px !important;
  }

  .footer-page {
    margin-top: 10px !important;
    margin-left: 0;
  }

  .footer {
    margin-top: 10px !important;
  }

  .transaction-header {
    margin-left: 0px !important;
    font-size: 9px !important;
    font-weight: 600 !important;
    line-height: 0px !important;
  }

  .signature {
    margin-left: 0px !important;
    font-size: 4px !important;
  }

  .cunstomer-hr {
    margin-left: 0px !important;
    flex: auto !important;
  }

  .receipt {
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
    margin-left: 3px !important;
  }

  .customer-hr {
    margin-left: 3px !important;
    flex: auto !important;
  }

  .customer-label {
    font-size: 7px !important;
    font-weight: 700 !important;
    line-height: 0px !important;
    text-align: left !important;
  }

  .aux-logo {
    margin-top: 0px !important;
    margin-right: 3px !important;
  }

  .customer-header {
    margin-left: 3px !important;
    font-size: 9px;
    font-weight: 600;
    line-height: 0px;
  }

  .receipt-header {
    font-size: 15px !important;
    margin-left: 0px !important;
    margin-top: 12px !important;
    color: black;
  }

  .receipt-align {
    margin-left: 3px !important;
    font-size: 7px !important;
    font-weight: 500 !important;
    line-height: 0px;
  }

  .receipt-card {
    margin-left: 12px !important;
    width: 93% !important;
  }

  .custom-footer {
    width: 72.666667% !important;
  }
}

@media only screen and (max-width: 600px) {
  //check alreday there
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .second-modal-text {
    padding-bottom: 10px;
    margin-top: 30px;
    color: white;
    font-size: 15px;
  }

  .modal-custom-class {
    .modal-body {
      background-color: #393b48 !important;
      width: 100% !important;
      left: 0% !important;
      margin-top: 0% !important;
      margin-bottom: 100% !important;
    }
  }

  .payment-home-pg {
    .card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100% !important;
      max-width: 450px;
      margin: 0 auto;
      height: 100%;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      background: #000000;
      padding: 0px;
      box-sizing: border-box;
      margin-top: 0px;
      margin-left: 50%;
      transform: translate(-50%, 0);
      overflow: auto;
    }

    .payment-method-button {
      flex: 1 0 calc(33.333% - 20px);
      max-width: calc(33.333% - 20px);
      box-sizing: border-box;
      padding: 8px;
      text-align: center;
      background-color: transparent;
      color: #000000;
      font-size: 13px;
      border: 2px solid #fdfcfc; /* Add a border */
      border-radius: 10px;
    }

    .cardMaster {
      position: relative;
      height: 85%;
      background-color: #4e505d;
      border: none !important;
    }
  }

  .card-name {
    font-weight: lighter;
    /* Make the name bold */
    margin-bottom: 3px;
    color: #ffffff;
    font-size: 11px;
  }

  .custom-footer {
    width: 72.666667% !important;
  }

  .tip-header h3 {
    color: white;
    text-align: center;
    font-size: 14px;
  }
}

@media (max-width: 375px) and (max-height: 667px) {
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .custom-input {
    margin-left: 8px;
  }

  .custom-amount-container {
    margin-left: 12px;
  }

  .receiptContainer {
    width: 100% !important;
    /* Adjust the width as needed */
    height: 400px !important;
    /* Adjust the height as needed */
  }

  .custom-footer {
    width: 72.666667% !important;
  }
}

@media screen and (max-width: 768px) {
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .receipt {
    font-size: 10px;
    width: 50%;
  }

  .custom-footer {
    width: 72.666667% !important;
  }

  .receipt-align {
    font-size: 6px;
    /* Adjust font size as needed */
    width: 37%;
    /* Adjust width as needed */
  }

  .receipt-card {
    margin-left: 12px !important;
    width: 93% !important;
  }
}

@media screen and (max-width: 768px) {
  .transaction-labels {
    font-size: 7px !important;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .custom-footer {
    width: 72.666667% !important;
  }

  .transaction-data {
    font-size: 6px !important;
  }
}

@media screen and (max-width: 768px) {
  .button-container .pay-now-button {
    height: 42px;
    margin-left: 15px;
    width: 100%;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .transaction-label {
    font-size: 7px !important;
  }

  .customer-details {
    font-size: 7px !important;
  }

  .user-transaction {
    font-size: 10px !important;
    text-align: right !important;
  }

  .amount-row {
    width: 103% !important;
  }

  .set-amount-width {
    width: 23.666667%;
  }

  .customers-detail {
    font-size: 7px !important;
    margin-left: 13px !important;
  }

  .align-row {
    width: 167% !important;
  }
}

@media screen and (max-width: 768px) {
  .signature {
    font-size: 4px !important;
    margin-top: 12px !important;
  }

  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .transaction-col {
    width: 32.333333% !important;
  }

  .footer-page {
    text-align: left !important;
    font-size: 4px;
    font-weight: 600;
    line-height: 0px;
  }

  .custom-input {
    margin-top: 8px;
    width: 76%;
    margin-left: 6px;
  }
}

@media (max-width: 430px) {
  .tip-sidebar {
    left: 0%;
    border-radius: 0px 0px 10px 10px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .receiptContainer {
    width: 101% !important;
    height: 112% !important;
    // margin-left: 0px!important;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
  .transaction-labels {
    font-size: 7px !important;
  }

  .custom-input {
    margin-top: 8px;
    width: 76%;
    margin-left: 9px !important;
  }

  .custom-footer {
    width: 72.666667% !important;
  }

  .button-container .pay-now-button {
    height: 42px;
    margin-left: 15px;
    width: 100%;
  }

  .transaction-data {
    font-size: 6px !important;
  }

  .transaction-label {
    font-size: 7px !important;
  }

  .customer-details {
    font-size: 7px !important;
  }

  .user-transaction {
    font-size: 10px !important;
    text-align: right !important;
  }

  .amount-row {
    width: 103% !important;
  }

  .set-amount-width {
    width: 23.666667%;
  }

  .customers-detail {
    font-size: 7px !important;
    margin-left: 13px !important;
  }

  .align-row {
    width: 167% !important;
  }

  .signature {
    font-size: 4px !important;
    margin-top: 12px !important;
  }

  .transaction-col {
    width: 32.333333% !important;
  }

  .footer-page {
    text-align: left !important;
    font-size: 4px;
    font-weight: 600;
    line-height: 0px;
    margin-left: 0;
  }

  .receiptContainer {
    width: 101% !important;
    height: 112% !important;
    // margin-left: 0px!important;
  }

  .receipt {
    font-size: 10px;
    width: 50%;
  }

  .receipt-align {
    font-size: 6px;
    width: 37%;
  }

  .receipt-card {
    margin-left: 12px !important;
    width: 93% !important;
  }

  .payment-details p {
    display: flex;
    justify-content: space-between;
  }

  .payment-details {
    font-size: 11px;
  }

  .payment-details span:nth-child(2) {
    text-align: right;
  }
}

.user-transaction {
  font-size: 8px;
  font-weight: 500;
  line-height: 0px;
  margin-left: 23px;
}

.customers-detail {
  font-size: 8px;
  font-weight: 500;
  line-height: 0px;
  margin-left: 18px;
}

.column-tip {
  width: 55px;
  display: flex;
  align-items: self-start;
  margin-left: 1px;
}

.option-btn.active {
  color: white;
  border-color: #38be51;
}

//////////////////////////////////////////////////////////Tip////////////////////////////////
.tip-header h3 {
  color: white;
  text-align: center;
}

.tip-body {
  display: flex;
  padding: 20px;
}

.tip-button-section {
  background-color: #282935;
  width: 100%;
  border-radius: 10px;
  margin: 5px;
  margin-top: 15px;
}

.tip-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.button-row {
  display: flex;
  // justify-content: space-between;
  margin-bottom: 0;
  /* Remove if not necessary */
}

.tip-button {
  background-color: #2980b9;
  /* Example button color */
  color: black;
  padding: 6px 12px;
  /* Smaller padding to reduce button size */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  /* Adjusted space between buttons */
  font-size: 0.9em;
  /* Adjust font size as necessary */
  width: 50px;
}

.tip-button:last-child {
  margin-right: 0;
  /* Remove margin for the last button in a row */
}

.tip-button:hover {
  background-color: #00B050 !important;
  color: white !important;
}

.custom-amount {
  margin-left: 0;
  /* Align with the left edge of the buttons */
  display: flex;
  flex-direction: column;
  /* Stack label and input vertically */
  align-items: flex-start;
  /* Align to the start of the flex container */
}

.custom-amount label {
  color: white;
}

.custom-amount-container {
  margin-top: 17px;
  margin-left: 12px;
  color: white;
  font-size: 12px;
}

.custom-amount input {
  width: 100%;
  /* Input takes the full width */
  padding: 8px;
  /* Match button padding for consistency */
  margin-top: 5px;
  /* Space between label and input */
  border: 1px solid #34495e;
  border-radius: 5px;
}

.payment-details {
  display: block;
  /* Default block display */
  color: white;
  /* Set the text color */
  margin-left: 5px;
  margin-top: 0px;
}

.payment-details p {
  display: flex;
  justify-content: space-between;
}

.payment-details span:nth-child(2) {
  text-align: right;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  .custom-amount-container {
    margin-top: 12px;
    margin-left: 18px;
    color: white;
    font-size: 11px;
  }
  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .payment-details {
    // margin-left: 65px;
    font-size: 11px;
  }

  //new
  .custom-input {
    margin-top: 8px;
    width: 76%;
    margin-left: 6px;
  }

  .tip-button:hover {
    background-color: #00B050 !important;
    color: white !important;
  }

  .payment-details p {
    display: flex;
    justify-content: space-between;
  }

  .payment-details span:nth-child(2) {
    text-align: right;
  }
}

//new
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) {
  .custom-amount-container {
    margin-top: 17px;
    margin-left: 12px;
    color: white;
    font-size: 12px;
  }

  .custom-buttom {
    margin-bottom: 16px;
  }

  .payment-details p {
    display: flex;
    justify-content: space-between;
  }

  .payment-details span:nth-child(2) {
    text-align: right;
  }

  .custom-input {
    margin-top: 8px;
    width: 76%;
    margin-left: 6px;
  }
}

.custom-input {
  margin-top: 8px;
  width: 76%;
  margin-left: 8px;
  font-weight: 600;
}

.value-align {
  width: 31%;
  display: flex;
  justify-content: start !important;
}

.payment-methods-buttons.single-button {
  display: flex;
  justify-content: center;
}

.payment-methods-buttons.two-buttons {
  display: flex;
  justify-content: space-between;
}

.payment-methods-buttons.multiple-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.payment-method-button {
  margin-bottom: 10px; /* Ensure there's some space between rows */
}

.first-modal-content {
  input::placeholder {
    color: #000000;
    /* Placeholder text color */
  }

  /* For Internet Explorer */
  input:-ms-input-placeholder {
    color: #000000;
    /* Placeholder text color */
  }

  .form-check-input:checked {
    background-color: #38be51 !important;
    border-color: #38be51 !important;
  }

  .form-check-input:focus:checked {
    box-shadow: 0 0 0 0.25rem rgba(56, 190, 81, 0.25) !important;
  }

  .form-check-input:checked::before {
    color: #fff !important;
  }
}

/* Tip Container */
.tip-container {
  margin-top: 20px;
  color: #ffffff;
}

/* Button Styling */
.tip-button {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  text-align: center;
  border-radius: 8px;
  border-right: .5px solid #636060;
  border-bottom: .5px solid #636060;
  background-color: #1c1c1e;
  color: #ffffff;
  cursor: pointer;
  transition: all 0.3s ease;
}

.tip-button.active {
  background-color: #00B050; /* Active button background */
  color: #ffffff; /* Active button text color */
}

.tip-button:hover {
  background-color: #00B050; /* Hover background */
  color: #ffffff; /* Hover text color */
}

/* Input Styling */
.custom-tip-input {
  width: 100%; /* Full width within its column */
  padding: 6px;
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #444;
  background-color: #464859;
  color: #ffffff;
  text-align: center;
}

.custom-tip-input:focus {
  border-color: #49a8ff;
  outline: none;
}

/* Row Alignment */
.row {
  margin-bottom: 0px; /* Add spacing between rows */
}

.align-items-center {
  display: flex;
  align-items: center;
}

/* Three-dot Menu: Top-right alignment */
.three-dot-menu {
  position: absolute;
  right: 10px;
  margin-bottom: 15px; /* Adds space between three dots and expiry date */
  margin-top: -20px;
}

/* Clickable Area */
.limited-click-area {
  flex-grow: 1; /* Ensures it expands to take available space */
  cursor: pointer;
}

.dropdown-menu {
  z-index: 1051 !important; /* Ensure it appears above all other elements */
}
